<template>
  <v-app :class="appClass">
    <v-navigation-drawer
      v-if="loggedIn"
      fixed
      clipped
      app
      disable-route-watcher
      v-model="drawer"
      :mini-variant="menuMiniVariant"
      mobile-break-point="960"
      :style="pageStyles"
      :class="{
        'sidebar-md': $vuetify.breakpoint.mdAndUp,
        'sidebar-sm': $vuetify.breakpoint.smAndDown,
      }"
    >
      <MainNav :mini="menuMiniVariant" @toggle-mini="toggleMini" />

      <OnboardingFixedOverlay :make-overlay="false" />
    </v-navigation-drawer>

    <MainToolbar @click="toggleDrawer" ref="nav" />

    <v-content app>
      <BillingAlert v-if="loggedIn" />

      <v-alert v-if="loggedIn && $vuetify.breakpoint.smAndDown" :value="true" dismissible color="error">
        {{ $t('OpenOnDesktop') }}
      </v-alert>

      <v-container
        id="container"
        :fluid="fluidContainer"
        :pa-0="$vuetify.breakpoint.smAndDown"
        :fill-height="meta.fillHeight"
        align-start
        :style="contentStyles"
      >
        <router-view />
      </v-container>
    </v-content>

    <router-view name="fab" ref="fab" />

    <MainFooter ref="footer" />

    <Onboarding v-model="showHelp" />

    <v-snackbar :timeout="2000" :top="true" :color="snackbarColor" :value="snackbar" @input="snackbarUpdate">
      <span class="ma-auto">
        {{ snackbarText }}
      </span>
    </v-snackbar>

    <v-snackbar :timeout="0" :top="true" :value="true" color="white" v-if="newVersion">
      <div class="black--text text-xs-center">
        {{ $t('newVersionAvailable', [newVersionText]) }}
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { AUTORELOAD_TIME } from '@/const';
import { MainToolbar, MainFooter } from '@/components/MainPageComponents';
import MainNav from '@/components/MainNav';
import BillingAlert from '@/components/billing/BillingAlert';
import Onboarding from '@/components/onboarding/Onboarding';
import OnboardingFixedOverlay from '@/components/onboarding/OnboardingFixedOverlay';
import { getManifest, getHomepage } from '@/api/app';

export default {
  name: 'App',

  metaInfo: {
    title: 'Main',

    titleTemplate: function(chunk) {
      return `${chunk} | Homestretch`;
    },

    meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
  },

  components: {
    MainNav,
    MainToolbar,
    MainFooter,
    BillingAlert,
    Onboarding,
    OnboardingFixedOverlay,
  },

  data() {
    return {
      drawer: true,
      footerHeight: 0,
      navHeight: 48,
      pageStyles: {},
      contentStyles: {},
      menuMiniVariant: localStorage.getItem('menuMiniVariant') === 'true',
      newVersion: null,
      newBuildNumber: null,
    };
  },

  created() {
    this.drawer = this.$vuetify.breakpoint.name !== 'xs';
    this.contentStyles.paddingTop = this.meta.zeroTopPadding ? 0 : '';
  },

  mounted() {
    this.$nextTick(() => {
      this.handlerFooterHeight();
      this.setupFavicon();

      window.addEventListener('load', this.handlerFooterHeight);
    });

    window.addEventListener('resize', this.handlerFooterHeight);

    this.checkManifestUpdate();

    setInterval(
      this.checkManifestUpdate,
      process.env.NODE_ENV === 'development' ? AUTORELOAD_TIME : AUTORELOAD_TIME * 60,
    );
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handlerFooterHeight);
  },

  computed: {
    ...mapState('auth', ['loggedIn']),
    ...mapState('site', ['showHelp', 'firstTimeGuide', 'snackbar', 'snackbarText', 'snackbarColor']),
    ...mapState('account', {
      firstTimeGuideShown: 'firstTimeGuide',
    }),
    ...mapGetters('site', ['favicon']),

    meta() {
      let meta = {};

      this.$route.matched.forEach(record => {
        const component = record.components.default;
        if (record.meta) {
          meta = Object.assign(meta, record.meta);
        }

        if (component && component.metaInfo) {
          if (typeof component.metaInfo === 'function') {
            meta = Object.assign(meta, component.metaInfo.call(this)); // Hack to let this.$i18n work
          } else {
            meta = Object.assign(meta, component.metaInfo);
          }
        }
      });

      return meta;
    },

    appClass() {
      const res = {};

      if (this.meta.fillHeight) {
        res['fill-height'] = true;
      }

      if (this.meta.class) {
        res[this.meta.class] = true;
      }

      return res;
    },

    fluidContainer() {
      return !this.meta.fixedContainer;
    },

    newVersionText() {
      let text = this.newVersion;

      if (process.env.NODE_ENV === 'development') {
        text += ' (' + this.$t('build', [this.newBuildNumber]) + ')';
      }

      return text;
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar', 'hideSnackbar', 'clearUnloadListener']),

    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    toggleMini() {
      this.menuMiniVariant = !this.menuMiniVariant;
      localStorage.setItem('menuMiniVariant', this.menuMiniVariant);
    },

    handlerFooterHeight() {
      this.$nextTick(function() {
        this.footerHeight = this.$refs.footer.$el.clientHeight;
        this.$store.commit('site/setWindowInnerWidth', window.innerWidth);
        this.$store.commit('site/setWindowInnerHeight', window.innerHeight);
        this.$store.commit('site/setFooterHeight', this.footerHeight);
        this.navHeight = this.$vuetify.application.top;

        Vue.set(this.pageStyles, 'height', `calc(100% - ${this.footerHeight + this.navHeight - 15}px)`);
        Vue.set(this.pageStyles, 'overflow', `auto`);
      });
    },

    setupFavicon() {
      const favicon = this.favicon;
      const link = document.createElement('link');

      link.type = 'image/x-icon';
      link.rel = 'icon';
      link.href = favicon;

      document.head.appendChild(link);
    },

    snackbarUpdate(value) {
      if (!value) {
        this.hideSnackbar();
      }
    },

    async checkManifestUpdate() {
      const data = await getManifest();

      if (process.env.VUE_APP_VERSION !== data.version || process.env.VUE_APP_BUILD_NUMBER !== data.build_number) {
        this.newVersion = data.version;
        this.newBuildNumber = data.build_number;

        await getHomepage();

        if (data.force_reload) {
          setTimeout(() => {
            this.clearUnloadListener();
            document.location.reload();
          }, 10000);
        }
      }
    },
  },

  watch: {
    'meta.zeroTopPadding'(value) {
      this.contentStyles.paddingTop = value ? 0 : '';
    },
  },
};
</script>

<style lang="scss">
$footer-height-md: 75px;
$toolbar-height-md: 64px;
$toolbar-height-xs: 48px;
$breakpoint-md: 958px;

#app.fill-height {
  main.v-content {
    height: 100%;
  }
}

main.v-content {
  margin: 0 0 146px 0;
}

.v-navigation-drawer {
  &.sidebar-md {
    margin-top: $toolbar-height-md !important;
    max-height: calc(100% - #{$footer-height-md + $toolbar-height-md}) !important;
  }

  &.sidebar-sm {
    margin-top: $toolbar-height-xs !important;
    max-height: calc(100% - #{$toolbar-height-xs}) !important;
    max-width: 250px;
  }
}

.footer-links {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding-left: 15px;
  }

  a {
    text-decoration: none;
    font-size: 13px;
    @media (max-width: 600px) {
      font-size: 10px;
    }

    &:hover {
      text-decoration: underline;
      transition: 0.4s;
    }
  }
}

#container {
  @media (max-width: $breakpoint-md) {
    padding: 0 3px !important;
  }
}

@media (max-width: $breakpoint-md) {
  main.v-content {
    padding-bottom: 30px !important;
  }
  aside {
    padding-bottom: 30px !important;
  }
}
</style>
