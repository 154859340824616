<template>
  <div class="pt-2">
    <span
      :style="{
        position: 'sticky',
        top: '1px',
        float: mini ? '' : 'right',
        'z-index': 999,
        width: mini ? '100%' : '',
        display: mini ? 'flex' : '',
        background: mini ? 'white' : '',
        'justify-content': mini ? 'flex-end' : '',
      }"
    >
      <v-btn icon @click.stop="toggleMini">
        <v-icon>{{ mini ? 'chevron_right' : 'chevron_left' }}</v-icon>
      </v-btn>
    </span>

    <v-list dense ref="nav">
      <slot v-for="item in menu">
        <slot v-if="hasItemPermission(item)">
          <v-subheader v-if="item.header" :class="item.debugOnly && 'cyan lighen-4'">
            {{ $t(item.header) }}
          </v-subheader>

          <v-divider v-else-if="item.divider" />

          <v-list-group v-else-if="item.children" v-model="item.model" no-action>
            <v-list-tile slot="item">
              <v-list-tile-action>
                <v-icon>{{ item.model ? item.icon : item['icon-alt'] }}</v-icon>
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>
                  {{ item.text }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile v-for="(child, key) in item.children" :key="key">
              <v-list-tile-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>
                  {{ child.text }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>

          <template v-else>
            <template v-if="mini">
              <v-tooltip right>
                <v-list-tile
                  :to="item.href"
                  :exact="isExact(item)"
                  :class="item.debugOnly && 'cyan lighten-4'"
                  slot="activator"
                >
                  <v-flex style="max-width: 56px">
                    <v-list-tile-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-tile-action>

                    <v-list-tile-action v-if="item.beta">
                      <span style="color: red; font-size: 0.8rem;">beta</span>
                    </v-list-tile-action>
                  </v-flex>

                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ $t(item.title) }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>

                <span>{{ $t(item.title) }}</span>
              </v-tooltip>
            </template>

            <template v-else>
              <v-list-tile :to="item.href" :exact="isExact(item)" :class="item.debugOnly && 'cyan lighten-4'">
                <v-flex style="max-width: 56px">
                  <v-list-tile-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-tile-action>

                  <v-list-tile-action v-if="item.beta">
                    <span style="color: red; font-size: 0.8rem;">beta</span>
                  </v-list-tile-action>
                </v-flex>

                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ $t(item.title) }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </template>
        </slot>
      </slot>
    </v-list>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import menu from '@/menu';

export default {
  name: 'MainNav',

  props: {
    mini: {
      type: Boolean,
    },
  },

  data() {
    return {
      menu,
      isDevelopment: process.env.NODE_ENV === 'development',
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState(['company']),

    currentRoute() {
      if (this.$route.matched && this.$route.matched.length) {
        return this.$route.matched[this.$route.matched.length - 1].name;
      }
      return null;
    },
  },

  methods: {
    toggleMini() {
      this.$emit('toggle-mini');
    },

    hasItemPermission(item) {
      if (item.debugOnly && !this.isDevelopment) {
        return false;
      }

      if (item.permission) {
        return this.hasPermission(item.permission);
      }

      return true;
    },

    isExact(item) {
      const currentRoute = this.currentRoute;

      if (item.exact) {
        return true;
      }

      const exactOn = item.exactOn;

      if (!exactOn) {
        return false;
      }

      if (Array.isArray(exactOn)) {
        return exactOn.includes(currentRoute);
      }

      return exactOn === currentRoute;
    },
  },
};
</script>
