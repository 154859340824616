<template>
  <div>
    <div v-if="value">
      <template v-for="(item, index) in elements">
        <v-tooltip
          :key="index"
          :ref="`tooltip${item.id}`"
          :value="true"
          :activator="item.el"
          fixed
          content-class="onboarding-tooltip"
          v-bind="item.tooltipOptions"
          :z-index="elementZIndex + 1"
        >
          <v-layout column align-center v-if="item.tooltipOptions.top">
            <v-flex text-xs-center mt-1 class="onboarding-tooltip-text">
              {{ item.text }}
            </v-flex>
            <v-flex mt-2>
              <v-icon>fa-arrow-down</v-icon>
            </v-flex>
          </v-layout>
          <v-layout row align-center v-if="item.tooltipOptions.right">
            <v-flex mr-2>
              <v-icon>fa-arrow-left</v-icon>
            </v-flex>
            <v-flex mb-1 class="onboarding-tooltip-text">
              {{ item.text }}
            </v-flex>
          </v-layout>
          <v-layout column align-center v-if="item.tooltipOptions.bottom">
            <v-flex>
              <v-icon>fa-arrow-up</v-icon>
            </v-flex>
            <v-flex text-xs-center mb-1 class="onboarding-tooltip-text">
              {{ item.text }}
            </v-flex>
          </v-layout>
          <v-layout row align-center v-if="item.tooltipOptions.left">
            <v-flex mb-1 class="onboarding-tooltip-text">
              {{ item.text }}
            </v-flex>
            <v-flex ml-2>
              <v-icon>fa-arrow-right</v-icon>
            </v-flex>
          </v-layout>
        </v-tooltip>
      </template>
      <!-- </div> -->

      <v-footer
        v-if="firstTimeGuide"
        app
        fixed
        color="grey darken-1"
        :height="footerHeight"
        :style="{ zIndex: elementZIndex + 2 }"
      >
        <v-layout row>
          <v-flex md4 class="onboarding--nextprev-controls">
            <v-btn color="white" flat large class="my-0" v-if="!isFirstPage" @click="prevPage">
              <div class="d-flex align-center">
                <v-icon size="45">
                  chevron_left
                </v-icon>
                <div class="d-inline-flex">
                  {{ $t('helpText.prevPage') }}
                </div>
              </div>
            </v-btn>
            <v-btn color="white" flat large class="my-0" v-if="isFirstPage && isDevelopment" @click="finishGuide">
              <div class="d-flex align-center">
                <v-icon size="45">
                  skip_next
                </v-icon>
                <div class="d-inline-flex">
                  {{ $t('helpText.skip') }}
                </div>
              </div>
            </v-btn>
          </v-flex>
          <v-flex md4 text-md-center class="onboarding--controls">
            <template v-for="(url, idx) in guidePages">
              <v-btn
                :key="idx"
                icon
                small
                :style="{ opacity: idx === currentPage ? '0.9' : '0.5' }"
                :disabled="idx === currentPage"
                @click="switchPage(idx)"
              >
                <v-icon color="white" size="18">
                  fiber_manual_record
                </v-icon>
              </v-btn>
            </template>
          </v-flex>
          <v-flex md4 text-md-right class="onboarding--nextprev-controls">
            <v-btn color="white" flat large class="my-0" v-if="!isLastPage" @click="nextPage">
              <div class="d-flex align-center">
                <div class="d-inline-flex">
                  {{ $t('helpText.nextPage') }}
                </div>
                <v-icon size="45">
                  chevron_right
                </v-icon>
              </div>
            </v-btn>
            <v-btn color="white" flat large class="my-0" v-if="isLastPage" @click="finishGuide">
              <div class="d-flex align-center">
                <div class="d-inline-flex">
                  {{ $t('helpText.finishGuide') }}
                </div>
              </div>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-footer>

      <OnboardingTransparentOverlay />
    </div>

    <v-fab-transition>
      <v-btn
        v-if="value && !firstTimeGuide"
        color="grey darken-2"
        dark
        fixed
        top
        right
        fab
        :style="{ zIndex: elementZIndex + 5 }"
        @click="hideOnboarding"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Toggleable from 'vuetify/es5/mixins/toggleable';
import Overlayable from 'vuetify/es5/mixins/overlayable';
import OnboardingTransparentOverlay from './OnboardingTransparentOverlay';
import { ONBOARDING_OVERLAY_ZINDEX, ONBOARDING_ELEMENT_ZINDEX } from '@/const';
import EventBus from '@/event-bus';

export default {
  name: 'Onboarding',
  mixins: [Overlayable, Toggleable],
  components: { OnboardingTransparentOverlay },

  props: {
    value: { type: Boolean, default: null },
  },

  data() {
    return {
      activeZIndex: ONBOARDING_OVERLAY_ZINDEX,
      elements: [],
      guidePages: ['/accounts', '/branches', '/trips/create', '/trips', '/support'],
      fab: false,
    };
  },

  computed: {
    ...mapState('site', ['footerHeight', 'firstTimeGuide']),
    ...mapState('site', {
      currentPage: 'firstTimeGuideCurrentPage',
    }),

    elementZIndex: () => ONBOARDING_ELEMENT_ZINDEX,

    isFirstPage() {
      return this.currentPage === 0;
    },

    isLastPage() {
      return this.currentPage === this.guidePages.length - 1;
    },

    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },
  },

  watch: {
    value() {
      if (this.value) {
        this.show();
      } else {
        this.hide();
      }
    },

    $route(route) {
      if (this.value && this.firstTimeGuide) {
        this.$nextTick(() => {
          const nav = document.querySelector('.v-navigation-drawer');
          const el = document.querySelector('.v-navigation-drawer .v-list__tile--active');
          if (el.offsetTop < nav.scrollTop || el.offsetTop > nav.scrollTop + nav.clientHeight) {
            if (el.offsetTop < nav.clientHeight) {
              nav.scrollTo(0, 0);
            } else {
              el.scrollIntoView();
            }
          }
        });
      }
    },
  },

  mounted() {
    if (this.value) {
      this.show();
    }
    EventBus.$on('onboarding-update', () => {
      if (this.value) {
        this.setUpElements();
      }
    });
  },

  methods: {
    ...mapActions('account', ['finishFirstTimeGuide']),

    show() {
      window.scrollTo(0, 0);
      if (this.firstTimeGuide && this.$route.fullPath !== this.guidePages[this.currentPage]) {
        this.$router.push(this.guidePages[this.currentPage]);
      }

      this.setUpElements();
      this.genOverlay();

      const app = document.querySelector('[data-app]');
      app.className += ' onboarding--active';
      if (this.firstTimeGuide) {
        app.className += ' onboarding--first-time-guide';
      }
    },

    hide() {
      this.removeOverlay();
      this.setGuides();

      const app = document.querySelector('[data-app]');
      app.classList.remove('onboarding--active');
      app.classList.remove('onboarding--first-time-guide');
    },

    hideOnboarding() {
      this.$store.commit('site/hideHelp');
    },

    getText(item) {
      return item.getAttribute('onboarding');
    },

    setUpElements() {
      const oldElms = this.elements.map(data => data.el);

      this.elements = [];
      const elms = document.querySelectorAll('[onboarding]');
      let id = 0;
      elms.forEach(el => {
        const text = el.getAttribute('onboarding');
        const placement = el.getAttribute('onboarding-placement') || null;
        const maxWidth = el.getAttribute('onboarding-maxwidth') || null;
        const zIndex = el.getAttribute('onboarding-zindex') || ONBOARDING_ELEMENT_ZINDEX;
        const nudgeTop = parseInt(el.getAttribute('onboarding-nudgetop')) || null;
        const nudgeRight = parseInt(el.getAttribute('onboarding-nudgeright')) || null;
        const nudgeBottom = parseInt(el.getAttribute('onboarding-nudgebottom')) || null;
        const nudgeLeft = parseInt(el.getAttribute('onboarding-nudgeleft')) || null;

        const elementData = {
          el,
          id,
          text,
          zIndex,
          tooltipOptions: {
            top: placement === 'top',
            right: placement === 'right',
            bottom: placement === 'bottom',
            left: placement === 'left',
            maxWidth,
            nudgeTop,
            nudgeRight,
            nudgeBottom,
            nudgeLeft,
          },
        };
        this.elements.push(elementData);
        id++;
      });

      const newElms = Array.from(elms);
      oldElms.forEach(el => {
        if (!newElms.includes(el)) {
          this.resetEl(el);
        }
      });

      // Allow refs population
      setTimeout(() => {
        this.setGuides();
      }, 1);
    },

    setGuides() {
      this.elements.forEach(element => {
        if (this.value) {
          const elPosition = this.getPropValue(element.el, 'position');
          if (elPosition !== 'absolute' && elPosition !== 'relative' && elPosition !== 'fixed') {
            element.el.style.position = 'relative';
          }
          element.el.style.zIndex = `${element.zIndex}`;
        } else {
          this.resetEl(element.el);
        }
      });

      this.updateDimensions();
    },

    resetEl(el) {
      // Reset el properties
      el.style.position = '';
      el.style.zIndex = '';
    },

    updateDimensions() {
      for (const key in this.$refs) {
        if (this.$refs[key] && this.$refs[key].length) {
          this.$refs[key][0].updateDimensions();
        }
      }
    },

    /**
     * Get an element CSS property on the page
     * Thanks to JavaScript Kit: http://www.javascriptkit.com/dhtmltutors/dhtmlcascade4.shtml
     * and intro.js https://github.com/usablica/intro.js/ where I found it
     */
    getPropValue(element, propName) {
      let propValue = '';
      if (element.currentStyle) {
        // IE
        propValue = element.currentStyle[propName];
      } else if (document.defaultView && document.defaultView.getComputedStyle) {
        // Others
        propValue = document.defaultView.getComputedStyle(element, null).getPropertyValue(propName);
      }

      // Prevent exception in IE
      if (propValue && propValue.toLowerCase) {
        return propValue.toLowerCase();
      } else {
        return propValue;
      }
    },

    switchPage(idx) {
      this.$router.push(this.guidePages[idx]);
      this.$store.commit('site/setFirstTimeGuidePage', idx);
    },

    prevPage() {
      this.switchPage(this.currentPage - 1);
    },

    nextPage() {
      this.switchPage(this.currentPage + 1);
    },

    finishGuide() {
      this.finishFirstTimeGuide();
      this.$router.push('/trips');
    },
  },
};
</script>

<style lang="scss">
@import '~vuetify-scss/settings/variables';

.onboarding-tooltip {
  /* box-shadow: none; */
  background: rgba(82, 82, 82, 0.7);
  color: #fff;
  max-width: 35vw;
  line-height: 1;
}

.onboarding-tooltip .v-icon {
  font-size: 30px;
  font-weight: bold;
  text-shadow: 1px 1px 2px black;
  vertical-align: inherit;
  color: inherit !important;
}

.onboarding-tooltip-text {
  font-size: 20px;
  font-weight: 400;
  text-shadow: 1px 1px 2px black;
  vertical-align: inherit;
  color: inherit !important;
}

.onboarding--nextprev-controls {
}

/* Active control dot */
.onboarding--controls .theme--light.v-btn.v-btn--disabled .v-icon {
  color: #fff !important;
}

/* Highlight active menu item */
.onboarding--first-time-guide .v-list__tile--active {
  z-index: 1000010;
  background-color: white;
}

/* Overlay for menu */
.onboarding--active .v-navigation-drawer .v-list {
  background: rgba(33, 33, 33, 0.46);
  min-height: 100%;
}

.onboarding--first-time-guide .showhelp {
  background: map-get($amber, darken-1);
}

.onboarding--overlay--transparent {
  opacity: 0;
}

.onboarding--overlay--round {
  -webkit-mask-image: radial-gradient(100px at 50% 50%, transparent 95%, black 100%);
}
.onboarding--container--round {
  position: absolute;
  top: 50%;
  left: 50%;
}

.webpack-hot-middleware-clientOverlay {
  z-index: 99999999 !important;
}
</style>
