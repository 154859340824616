/*
 * Main file for company site (*.co.homestretch.ch)
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-polyfill';

// import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'roboto-fontface/css/roboto/sass/roboto-fontface-medium.scss';
import 'material-design-icons/iconfont/material-icons.css';
import 'font-awesome/css/font-awesome.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.css';
import './plugins/jquery';
import '../vendor/sticky-table-headers/jquery.stickytableheaders.js';

import './styles/style.scss';
import '../vendor/orgchart.js/orgchart.css';
import './styles/orgchart.scss';

import Vue from 'vue';
import Vuetify from 'vuetify';
import Nl2br from 'vue-nl2br';
import router from './router';
import i18n from './i18n';
import store from './store';

import App from './App';
import VueSubmit from './components/VueSubmit';
import BtnAsync from './components/BtnAsync';
import DebugOnly from './components/DebugOnly';
import ErrorAlert from './components/ErrorAlert';
import VueOnboarding from './components/onboarding';
import Form from './components/Form';
import Grid from './components/Grid';
import icons from './components/icons';
import TripPointInfo from './components/trip/events/TripPointInfo';
import Clickable from './directives/Clickable';

Vue.use(Vuetify, {
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});

Vue.config.productionTip = false;
Vue.component('nl2br', Nl2br);
Vue.component('v-submit', VueSubmit);
Vue.component('btn-async', BtnAsync);
Vue.component('debug-only', DebugOnly);
Vue.component('error-alert', ErrorAlert);
Vue.component('Form', Form);
Vue.component('Grid', Grid);
Vue.component('TripPointInfo', TripPointInfo);
Vue.directive('clickable', Clickable);
icons.install();
Vue.use(VueOnboarding);

Vue.prototype.$duration = function(value, format) {
  return i18n.d(value, format === 'short' ? 'durationShort' : 'duration');
};

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  store,
  router,
  template: '<App/>',
  components: { App },
  created() {
    global.$t = this.$t;
  },
});
