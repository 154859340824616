<template>
  <div v-if="billingAlert">
    <v-snackbar
      v-model="snackbar"
      color="error"
      :top="true"
      auto-height
      :absolute="true"
      :timeout="600000000"
      v-if="billingText"
    >
      {{ billingText }}
      <v-btn dark flat @click="hideSnackbar">
        {{ $t('Close') }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      snackbar: true,
    };
  },
  computed: {
    ...mapState(['billing']),
    ...mapGetters('billing', ['paidLeft', 'currentPeriodEnd', 'billingAlert']),
    billingText() {
      if (this.billingAlert) {
        return this.$t(`billing_alert.${this.billing.status}`, [this.currentPeriodEndText, this.paidDaysLeftText]);
      }
      return '';
    },
    paidDaysLeftText() {
      return this.$d(this.paidLeft, 'durationDays');
    },
    currentPeriodEndText() {
      return this.$d(this.currentPeriodEnd, 'standard');
    },
    status() {
      return this.billing.status;
    },
  },
  methods: {
    hideSnackbar() {
      this.snackbar = false;
    },
  },
  watch: {
    status() {
      this.snackbar = this.billingAlert;
    },
  },
};
</script>
