const handler = (e, binding) => {
  binding.value(e);
};

const Clickable = {
  bind(el, binding, vnode) {
    el.addEventListener('dblclick', e => handler(e, binding));
  },

  unbind(el, binding, vnode) {
    el.removeEventListener('dblclick', e => handler(e, binding));
  },
};
export default Clickable;
