export default [
  { href: '/accounts', title: 'Employees', icon: 'mdi-account-group', permission: 'accounts get' },
  { href: '/branches', title: 'Company structure', icon: 'business', permission: 'branches get' },

  { divider: true, permission: 'distributions get' },
  { header: 'LotPlanning', permission: 'distributions get' },
  {
    href: { name: 'distributionList' },
    icon: 'history',
    title: 'LotPlanning',
    permission: 'distributions get',
    exactOn: ['distributionCreate', 'distributionImport', 'distributionManual', 'distributionAdditional'],
  },

  { divider: true, permission: ['trips get', 'monitoring get', 'places get', 'reports get'] },
  { header: 'Dispatching', permission: ['trips get', 'monitoring get', 'places get', 'reports get'] },
  {
    href: { name: 'trips' },
    title: 'Trips',
    icon: 'directions_car',
    exactOn: ['createTrip', 'tripsMonitoring'],
    permission: 'trips get',
  },
  { href: { name: 'tripsMonitoring' }, title: 'Monitoring', icon: 'fa-eye', permission: 'monitoring get' },
  { href: '/points', title: 'Points demands', icon: 'assignment', permission: 'trips get' },
  { href: '/favorites', title: 'Favorites', icon: 'place', permission: 'places get' },
  { href: '/reports', title: 'Settings reports', icon: 'view_list', permission: 'reports get' },

  { divider: true, permission: ['transports get', 'orders get', 'shifts get', 'contractors get'] },
  { header: 'Resources', permission: ['transports get', 'orders get', 'shifts get', 'contractors get'] },
  { href: '/transports', title: 'Transport', icon: 'mdi-car-multiple', permission: 'transports get' },
  { href: '/orders', title: 'Orders', icon: 'mdi-format-list-checks', permission: 'orders get' },
  { href: '/shifts', title: 'Shifts', icon: 'mdi-clock-outline', permission: 'shifts get', debugOnly: true },
  { href: '/contractors', title: 'Contractors', icon: 'mdi-account-tie', permission: 'contractors get' },

  { divider: true, permission: 'billing', debugOnly: true },
  { header: 'Billing', permission: 'billing', debugOnly: true },
  { href: '/billing/prices', title: 'Price list', icon: 'assessment', permission: 'billing', debugOnly: true },
  {
    href: '/billing/payment',
    title: 'Go to payment',
    icon: 'account_balance_wallet',
    permission: 'billing',
    debugOnly: true,
  },
  { href: '/billing/invoices', title: 'Invoices', icon: 'receipt', permission: 'billing', debugOnly: true },

  { divider: true, permission: ['company update', 'pointFunctions', 'roles get', 'zones get'] },
  { header: 'Settings', permission: ['company update', 'pointFunctions', 'roles get', 'zones get'] },
  { href: '/tags', title: 'Tags', icon: 'mdi-pound', permission: 'tags get' },
  {
    href: '/pointfunctions',
    title: 'PointFunctionsSettings',
    icon: 'mdi-sign-direction',
    permission: 'pointFunctions get',
  },
  { href: '/company/settings', title: 'Company settings', icon: 'settings', permission: 'company update' },
  { href: '/company/roles', title: 'roles.list.title', icon: 'mdi-key', permission: 'roles get' },
  { href: '/zones', title: 'Zones', icon: 'mdi-shape-outline', permission: 'zones get' },

  { divider: true },
  { header: 'User' },
  { href: '/profile', title: 'Profile', icon: 'person' },
  { href: '/support', title: 'Support', icon: 'help' },
  { href: '/logout', title: 'Logout', icon: 'lock' },
];
