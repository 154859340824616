<script>
import { mapState } from 'vuex';
import { ONBOARDING_ELEMENT_ZINDEX } from '@/const';
import Overlayable from 'vuetify/es5/mixins/overlayable';

export default {
  name: 'OnboardingTransparentOverlay',
  mixins: [Overlayable],

  data() {
    return {
      activeZIndex: ONBOARDING_ELEMENT_ZINDEX + 2,
      isActive: true,
    };
  },

  computed: {
    ...mapState('site', ['firstTimeGuide']),
  },

  created() {
    this.genOverlay();
    this.overlay.classList.add('onboarding--overlay--transparent');
    if (!this.firstTimeGuide) {
      this.overlay.addEventListener('click', () => {
        this.$store.commit('site/hideHelp');
      });
    }
  },

  render() {
    return <div></div>;
  },
};
</script>
