import http from './httpV2';

async function getHomepage(): Promise<object> {
  const res = await http.get(window.location.pathname + window.location.search, {
    baseURL: '/',
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
  return res.data;
}

async function getManifest(): Promise<object> {
  const res = await http.get('/manifest.json', {
    baseURL: '/',
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
  return res.data;
}

export { getHomepage, getManifest };
