<template>
  <div class="grid">
    <grid-helper
      :headers="headers"
      :items="myItems"
      :pagination="myPagination"
      @update:pagination="updatePagination"
      :total-items="totalRows"
      :filter="filterFunc"
      :must-sort="true"
      :rows-per-page-items="rowsPerPageItems"
      :hide-actions="hideActions"
      ref="grid"
    >
      <template slot="subHeader" v-if="totals">
        <tr>
          <th
            v-for="(header, i) of totals"
            :key="i"
            :colspan="header.colspan"
            :class="header.class + ` text-xs-${header.align} || 'left'`"
            style="color: #000; font-weight: bold"
          >
            {{ header.value }}
          </th>
        </tr>
      </template>

      <template slot="pageText" slot-scope="data">
        {{ data.pageStart }}-{{ data.pageStop }} {{ $t('of') }} {{ data.totalRelation }}{{ data.itemsLength }}
      </template>

      <template slot="no-data">
        <slot name="no-data" />
      </template>

      <template slot="items" slot-scope="row">
        <tr :active="getTrActive(row.item)" :class="getTrClass(row.item)" :id="row.item.external_id">
          <td
            v-for="(field, key) in fields"
            :key="key"
            :style="tdStyle(field)"
            :class="tdClass(field)"
            @click="handleClick(row.item, key)"
          >
            <template v-if="hasSlot(key)">
              <slot :name="key" :item="row.item" />
            </template>

            <template v-else-if="key == 'actions'">
              <v-menu offset-y>
                <v-btn color="primary" size="sm" flat dark slot="activator">
                  {{ field.text || $t('Actions') }}
                </v-btn>

                <v-list dense v-if="!field.menu">
                  <v-list-tile @click="details(row.item)" v-if="!hideDetailsAction">
                    <v-list-tile-title v-if="canUpdate && row.item.can_update">
                      {{ $t('Edit') }}
                    </v-list-tile-title>

                    <v-list-tile-title v-else>
                      {{ $t('Details') }}
                    </v-list-tile-title>
                  </v-list-tile>

                  <v-list-tile
                    v-if="canDelete && row.item.can_delete"
                    @click="askDelete(row.item, row.index, $event.target)"
                  >
                    <v-list-tile-title class="pink--text text--darken-2">
                      {{ $t('Delete') }}
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>

                <v-list dense v-else>
                  <v-list-tile v-for="action in field.menu" :key="action.title" @click="action.handler(row.item)">
                    <v-list-tile-title :class="action.class">
                      <v-icon v-if="action.icon" class="mr-1" small>
                        {{ action.icon }}
                      </v-icon>

                      {{ action.title }}
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </template>

            <template v-else-if="field.component && field.format">
              <component :is="field.component" :item="row.item" :value="field.format(row.item[key], row.item)" />
            </template>

            <template v-else-if="field.component">
              <component :is="field.component" :item="row.item" :value="row.item[key]" />
            </template>

            <template v-else-if="field.format">
              {{ field.format(row.item[key], row.item) }}
            </template>

            <template v-else-if="field.formatHtml">
              <div v-html="field.formatHtml(row.item[key], row.item)" />
            </template>

            <template v-else-if="showSecondRow">
              <div class="d-inline-block text-xs-center pt-1" :class="{ 'row-comment': row.item.comment }">
                {{ row.item[key] }}
              </div>

              <div class="d-inline-block" v-if="row.item.comment || (row.item.contacts && row.item.contacts.length)">
                <template>
                  <v-tooltip bottom v-if="!openComments">
                    <div class="d-flex ml-2" slot="activator">
                      <v-icon small color="grey darken-1">
                        comment
                      </v-icon>
                    </div>

                    <span>{{ $t('Has contact or comment') }}</span>
                  </v-tooltip>

                  <div class="d-flex ml-2" v-else>
                    <v-icon small color="grey darken-1">
                      comment
                    </v-icon>
                  </div>
                </template>
              </div>

              <div v-if="row.item.battery" class="pt-2">
                <BatteryInfo :value="row.item.battery" />
              </div>
            </template>

            <template v-else>
              {{ get(row.item, key) }}
            </template>

            <template v-if="field.formatSubHtml">
              <div class="cell-subheader" v-html="field.formatSubHtml(row.item[key], row.item)" />
            </template>
          </td>
        </tr>

        <template v-if="showSecondRow">
          <tr :active="getTrActive(row.item)" :class="getTrClass(row.item)" v-if="openComments && row.item.comment">
            <td class="text-xs-left trip-comment caption" :colspan="Object.values(fields).length">
              <span>{{ $t('Comment') }}: <nl2br tag="span" :text="row.item.comment"/></span>
            </td>
          </tr>

          <tr
            :active="getTrActive(row.item)"
            :class="getTrClass(row.item)"
            v-if="openComments && row.item.contacts && row.item.contacts.length"
          >
            <td class="text-xs-left trip-comment caption" :colspan="Object.values(fields).length">
              <v-layout row>
                <v-flex v-for="contact in row.item.contacts" :key="contact.id">
                  <v-layout row>
                    <v-flex shrink>
                      <v-icon small>
                        phone
                      </v-icon>
                    </v-flex>

                    <v-flex shrink>
                      <div>{{ contact.name }}</div>

                      <div v-if="contact.jobTitle">
                        <i>{{ contact.jobTitle }}</i>
                      </div>

                      <template v-if="contact.note">
                        <div>{{ $t('Note') }}: {{ contact.note }}</div>
                      </template>

                      <template v-for="phoneNumber in contact.phones">
                        <div :key="phoneNumber.id">
                          {{ phoneNumber.phone }}
                        </div>
                      </template>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </td>
          </tr>
        </template>
      </template>
    </grid-helper>

    <DeleteDialog
      v-if="deleteDialog"
      @delete="handleDelete"
      :delete-item="deleteItemName"
      :delete-error="deleteError"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
/* global $ */
import api from '@/api';
import _ from 'lodash';
import BatteryInfo from '@/components/BatteryInfo';

import GridHelper from './GridHelper';
import DeleteDialog from '@/components/modals/DeleteDialog';
/* eslint-disable vue/require-default-prop */
export default {
  name: 'Grid',

  components: {
    GridHelper,
    BatteryInfo,
    DeleteDialog,
  },

  data() {
    return {
      myItems: [],
      totalRows: 0,
      myPagination: {},
      defaultPagination: {
        page: 1,
        rowsPerPage: 100,
        sortBy: null,
        descending: false,
      },
      rowsPerPageItems: [100, { text: this.$t('All'), value: -1 }],
      myCanCreate: true,
      deleteDialog: false,
      deleteError: null,
      deleteItem: null,
    };
  },

  props: {
    fields: {
      type: [Object, Array],
      default: null,
    },
    totals: {
      type: Array,
      default: null,
    },
    resource: {
      type: String,
    },
    api: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    filter: {
      type: [Object, String],
    },
    filterFunc: {
      type: Function,
    },
    trActive: Function,
    trClass: Function,
    canUpdate: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canCreate: {
      type: Boolean,
      default: true,
    },
    url: {
      type: Function,
    },
    route: {
      type: String,
    },
    pagination: {
      type: Object,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideDetailsAction: Boolean,
    nowrap: Boolean,
    openComments: {
      type: Boolean,
    },
    showSecondRow: {
      type: Boolean,
      default: false,
    },
    deleteAccount: {
      type: Boolean,
      default: false,
    },
    deletePlace: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (!this.hideActions) {
      for (const key in this.fields) {
        const field = this.fields[key];

        if (field.defaultSort === -1) {
          this.defaultPagination.descending = true;
          this.defaultPagination.sortBy = key;
          break;
        } else if (field.defaultSort) {
          this.defaultPagination.sortBy = key;
        }
      }

      this.myPagination = Object.assign({}, this.myPagination, this.defaultPagination, this.pagination);
    }
  },

  async mounted() {
    if (!this.resource && !this.api && !(this.items instanceof Array)) {
      throw new Error('grid has no source');
    }

    await this.getItems();

    const $el = $(this.$refs.grid.$el);
    const $table = $(this.$refs.grid.$el.firstChild);

    $table.stickyTableHeaders({
      zIndex: 1,
      container: $el,
      fixedOffset: $('.toolbar'),
    });

    this.unwatch = this.$watch(
      function() {
        return this.$vuetify.application.left;
      },

      function() {
        setTimeout(() => {
          $table.stickyTableHeaders('updateWidth');
          $table.stickyTableHeaders('toggleHeaders');
        }, 300);
      },
    );
  },

  beforeDestroy() {
    this.unwatch();
    $(this.$refs.grid.$el.firstChild).stickyTableHeaders('destroy');
  },

  watch: {
    async myPagination() {
      await this.getItems();
      this.$emit('update:pagination', this.myPagination);
    },

    async pagination() {
      Object.assign(this.myPagination, this.pagination);
    },

    async items() {
      await this.getItems();
    },

    resource() {
      return this.getItems();
    },

    api() {
      return this.getItems();
    },

    filter() {
      this.getItems();
    },
  },

  methods: {
    refresh() {
      this.getItems();
    },

    myApi() {
      return this.api || _.get(api, this.resource);
    },

    get(row, key) {
      return _.get(row, key);
    },

    getRouteParams(id) {
      if (this.route) {
        return {
          name: this.route,
          params: {
            id: id,
            back: this.$route.path,
          },
        };
      }

      if (this.url) {
        return this.url(id);
      }

      return this.$route.path + '/' + id;
    },

    makeQuery() {
      const query = {};

      if (this.myPagination.page !== this.defaultPagination.page) {
        query.page = this.myPagination.page - 1;
      }

      if (this.myPagination.rowsPerPage !== this.defaultPagination.rowsPerPage) {
        query.pageSize = this.myPagination.rowsPerPage;
      }

      if (this.myPagination.sortBy !== this.defaultPagination.sortBy) {
        query.sort = this.myPagination.sortBy;
      }

      if (this.myPagination.descending !== this.defaultPagination.descending) {
        query.sortDesc = this.myPagination.descending ? '1' : '0';
      }

      return query;
    },

    getItems: _.debounce(async function() {
      if (this.items !== null) {
        if (this.filterFunc) {
          this.myItems = this.filterFunc(this.items, this.filter);
        } else {
          this.myItems = this.items;
        }

        return;
      }

      const data = await this.myApi().list(
        this.filter
          ? {
              ...this.myPagination,
              filter: this.filter,
            }
          : this.myPagination,
      );

      if (data.total > 0 && data.results.length === 0) {
        this.myPagination.page = 1;
        this.getItems();
      } else {
        this.$emit('update:can-create', data.can_create);

        this.totalRows = data.total;
        this.myCanCreate = this.canCreate && data.can_create;
        this.myItems = data.results;

        this.$emit('update:data', data);
      }
    }, 100),

    updatePagination(value) {
      if (!this.hideActions) {
        this.myPagination = value;
      }
    },

    details(item) {
      if (this.$listeners.details) {
        this.$emit('details', item);

        return;
      }

      if (!this.myApi()) {
        return false;
      }

      if (!this.hideDetailsAction) {
        this.$router.push(this.getRouteParams(item.id));
      }
    },

    askDelete(item) {
      this.deleteItem = item;
      this.deleteError = null;
      this.deleteDialog = true;
    },

    async handleDelete() {
      try {
        const item = this.deleteItem;

        await this.myApi().remove(item.id);

        this.deleteItem = null;
        this.deleteDialog = false;

        await this.getItems();

        this.$emit('delete', item);
      } catch (err) {
        this.deleteError = err;
      }
    },

    handleAdd() {
      this.$router.push(this.$route.path + '/new');
    },

    handleClick(item, field) {
      if (field !== 'actions') {
        this.details(item);
      }

      this.$emit('click', item, field);
    },

    getTrActive(row) {
      return this.trActive ? this.trActive(row) && 'active' : undefined;
    },

    getTrClass(row) {
      return this.trClass ? this.trClass(row) : undefined;
    },

    tdClass(field) {
      const res = [];

      if (field.class) {
        res.push(field.class);
      }

      if (field.align === 'right') {
        res.push('text-xs-right');
      } else if (field.align === 'center') {
        res.push('text-xs-center');
      }

      return res;
    },

    tdStyle(field) {
      const nowrap = field.nowrap !== undefined ? field.nowrap : this.nowrap;

      return {
        ...field.style,
        whiteSpace: nowrap ? 'nowrap' : null,
        // overflowWrap: !nowrap ? 'break-word' : null
      };
    },

    hasSlot(name) {
      return this.$scopedSlots[name] !== undefined;
    },
  },

  computed: {
    headers() {
      const result = [];

      for (const key in this.fields) {
        const field = this.fields[key];

        result.push({
          text: field.label && this.$t(field.label),
          class: field.class,
          groupText: field.groupLabel && this.$t(field.groupLabel),
          groupColspan: field.groupColspan,
          value: key,
          align: field.headerAlign || (field.align === undefined || field.align === 'left' ? 'left' : 'center'),
          sortable: field.sortable,
        });
      }

      return result;
    },

    deleteItemName() {
      if (this.deleteItem && this.deleteAccount) {
        return (
          this.$t('common_strings.account') + ' "' + this.deleteItem.first_name + ' ' + this.deleteItem.last_name + '"'
        );
      } else if (this.deleteItem && this.deletePlace) {
        return this.$t('common_strings.from_place') + ' "' + this.deleteItem.name + '"';
      } else {
        return '';
      }
    },
  },
};
</script>

<style>
.read:after {
  display: none;
}

.row-comment {
  vertical-align: super;
}
</style>
