/* eslint-disable semi */

import VDataTable from 'vuetify/es5/components/VDataTable/VDataTable';

export default {
  extends: VDataTable,

  methods: {
    genTHead() {
      if (this.hideHeaders) {
        return; // Exit Early since no headers are needed.
      }

      let children = [];

      /* added */
      const more = [];
      if (this.$slots.subHeader) {
        more.push(this.$slots.subHeader);
      }
      /* /added */

      if (!this.hideActions) {
        more.push(
          this.genTR([
            this.$createElement(
              'td',
              {
                attrs: { colspan: '100%' },
              },
              this.genActions(),
            ),
          ]),
        );
      }

      if (this.$scopedSlots.headers) {
        const row = this.$scopedSlots.headers({
          headers: this.headers,
          indeterminate: this.indeterminate,
          all: this.all,
        });

        children = [this.needsTR(row) ? this.genTR(row) : row, ...more, this.genTProgress()];
      } else {
        let topHeaders = [];
        const hasGroup = this.headers.some(o => o.groupText !== undefined);
        if (!hasGroup) {
          topHeaders = this.headers;
        } else {
          const bottomHeaders = [];
          for (let i = 0; i < this.headers.length; i++) {
            if (this.headers[i].groupText) {
              topHeaders.push({
                ...this.headers[i],
                text: this.headers[i].groupText,
                align: 'center',
                sortable: false,
                colspan: this.headers[i].groupColspan,
              });
              for (let j = 0; j < this.headers[i].groupColspan; j++) {
                bottomHeaders.push({
                  ...this.headers[i + j],
                });
              }
            } else {
              topHeaders.push({
                ...this.headers[i],
                rowspan: 2,
              });
            }
            if (this.headers[i].groupColspan) {
              i += this.headers[i].groupColspan - 1;
            }
          }
          const bottomRow = bottomHeaders.map(o => this.genHeader(o));
          more.unshift(
            this.genTR(bottomRow, {
              style: {
                height: '28px',
              },
            }),
          );
        }

        const topRow = topHeaders.map(o => this.genHeader(o));
        const checkbox = this.$createElement('v-checkbox', {
          props: {
            dark: this.dark,
            light: this.light,
            color: this.selectAll === true ? '' : this.selectAll,
            hideDetails: true,
            inputValue: this.all,
            indeterminate: this.indeterminate,
          },
          on: { change: this.toggle },
        });

        this.hasSelectAll && topRow.unshift(this.$createElement('th', [checkbox]));

        children = [
          this.genTR(
            topRow,
            hasGroup
              ? {
                  style: {
                    height: '28px',
                  },
                }
              : undefined,
          ),
          ...more,
          this.genTProgress(),
        ];
      }

      return this.$createElement('thead', [children]);
    },

    genHeaderData(header, children) {
      const classes = ['column'];
      const data = {
        attrs: {
          role: 'columnheader',
          scope: 'col',
          rowspan: header.rowspan || null,
          colspan: header.colspan || null,
          width: header.width || null,
          'aria-label': header[this.headerText] || '',
          'aria-sort': 'none',
        },
      };

      if (('sortable' in header && header.sortable) || !('sortable' in header)) {
        this.genHeaderSortingData(header, children, data, classes);
      } else {
        data.attrs['aria-label'] += ': Not sorted.'; // TODO: Localization
      }

      classes.push(`text-xs-${header.align || 'right'}`);
      if (Array.isArray(header.class)) {
        classes.push(...header.class);
      } else if (header.class) {
        classes.push(header.class);
      }
      data.class = classes;

      return [data, children];
    },

    genActionsFooter() {
      if (this.hideActions) {
        return null;
      }

      if (this.itemsLength <= this.computedPagination.rowsPerPage) {
        return null;
      }

      return this.$createElement(
        'div',
        {
          class: this.classes,
        },
        this.genActions(),
      );
    },
  },
};
