export default {
  data() {
    return {
      loadingPromise: false,
      clickParams: [],
      onClick: () => {},
    };
  },
  props: {
    flat: Boolean,
    icon: Boolean,
    small: Boolean,
    disabled: Boolean,
    loading: Boolean,
  },
  render(h) {
    if (this.$listeners.click) {
      this.onClick = this.$listeners.click;
    }
    if (this.$attrs.clickParams) {
      this.clickParams = this.$attrs.clickParams;
    }

    return h(
      'v-btn',
      {
        props: {
          ...this.$props,
          loading: this.loadingPromise || this.loading,
        },
        attrs: {
          ...this.$attrs,
          clickParams: undefined,
        },
        on: {
          ...this.$listeners,
          click: this.click,
        },
      },
      [this.$slots.default],
    );
  },
  methods: {
    async click() {
      try {
        this.loadingPromise = true;
        if (this.clickParams instanceof Array) {
          await this.onClick(...this.clickParams);
        } else {
          await this.onClick(this.clickParams);
        }
        this.loadingPromise = false;
      } catch (err) {
        this.loadingPromise = false;
        throw err;
      }
    },
  },
};
