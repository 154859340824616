import Vue from 'vue';

let DebugOnly = Vue.extend({
  functional: true,

  render(h) {
    return h();
  },
});

if (process.env.NODE_ENV !== 'production') {
  const setColors = children => {
    if (!children) {
      return;
    }
    for (const el of children) {
      if (el.componentOptions && el.componentOptions.tag === 'v-card') {
        el.data.staticClass = (el.data.staticClass || '') + ' cyan lighten-4';
      }
      if (el.componentOptions && el.componentOptions.tag === 'v-btn') {
        el.componentOptions.propsData.color = el.componentOptions.propsData.color || 'cyan lighten-4';
      }
      setColors(el.children);
    }
    return children;
  };

  DebugOnly = Vue.extend({
    functional: true,

    render(h, { children }) {
      return setColors(children);
    },
  });
}

export default DebugOnly;
