<template>
  <v-form v-model="valid" ref="form" @submit="submit">
    <error-alert :value="error" />

    <slot v-for="item in fields">
      <template v-if="hasSlot(item.name)">
        <slot :name="item.name" :field="item" :value="value.data[item.name]" :errors="getErrors(item.name)" />
      </template>

      <template v-else-if="item instanceof Array">
        <v-layout row wrap>
          <slot v-for="subitem in item">
            <v-flex md6>
              <!-- FIXME: calculate right width -->
              <text-field
                :type="subitem.type"
                :mask="subitem.mask"
                :name="subitem.name"
                :placeholder="subitem.placeholder"
                :hint="$t(subitem.hint)"
                :persistent-hint="subitem.persistentHint"
                :maxlength="subitem.maxlength"
                :suffix="subitem.suffix"
                :prepend-icon="subitem.icon"
                v-model.trim="value.data[subitem.name]"
                :rules="subitem.rules"
                :error-messages="getErrors(subitem.name)"
                :readonly="subitem.readonly"
                :disabled="subitem.disabled"
                :browser-autocomplete="subitem.autocomplete === false ? 'off' : null"
                @enter="enter"
                @input="
                  val => {
                    if (subitem.onInput) {
                      subitem.onInput(val);
                    }
                  }
                "
              >
                <template #label>
                  <strong v-if="subitem.isRequired" class="red--text">* </strong>
                  {{ $t(subitem.label) }}
                </template>

                <template v-if="subitem.append" #append>
                  <v-icon v-if="subitem.append.icon && subitem.append.action" @click="subitem.append.action">
                    {{ subitem.append.icon }}
                  </v-icon>
                </template>
              </text-field>
            </v-flex>
          </slot>
        </v-layout>
      </template>

      <v-select
        v-else-if="item.type === 'select'"
        :name="item.name"
        :hint="$t(item.hint)"
        :persistent-hint="item.persistentHint"
        :items="item.items"
        :item-text="item.item_text"
        :item-value="item.item_value"
        :prepend-icon="item.icon"
        v-model="value.data[item.name]"
        :rules="item.rules"
        :error-messages="getErrors(item.name)"
        :readonly="item.disabled"
        :disabled="item.disabled"
        @enter="enter"
      >
        <template #label>
          <strong v-if="item.isRequired" class="red--text">* </strong>
          {{ $t(item.label) }}
        </template>
      </v-select>

      <v-checkbox
        v-else-if="item.type === 'checkbox'"
        v-model="value.data[item.name]"
        :name="item.name"
        :disabled="item.disabled"
      >
        <template #label>
          <strong v-if="item.isRequired" class="red--text">* </strong>
          {{ $t(item.label) }}
        </template>
      </v-checkbox>

      <text-field
        v-else-if="item.type === 'password'"
        :hint="$t(item.hint)"
        :persistent-hint="item.persistentHint"
        :maxlength="item.maxlength"
        :placeholder="item.placeholder"
        :suffix="item.suffix"
        :prepend-icon="item.icon"
        v-model.trim="value.data[item.name]"
        :append-icon="!pw[item.name] ? 'visibility' : 'visibility_off'"
        @click:append="pw[item.name] = !pw[item.name]"
        :type="!pw[item.name] ? 'password' : 'text'"
        :rules="item.rules"
        :error-messages="getErrors(item.name)"
        :readonly="item.readonly"
        :disabled="item.disabled"
        :browser-autocomplete="item.autocomplete === false ? 'off' : null"
        @enter="enter"
      >
        <template #label>
          <strong v-if="item.isRequired" class="red--text">* </strong>
          {{ $t(item.label) }}
        </template>
      </text-field>

      <text-field
        v-else
        :type="item.type"
        :mask="item.mask"
        :name="item.name"
        :placeholder="item.placeholder"
        :hint="$t(item.hint)"
        :persistent-hint="item.persistentHint"
        :maxlength="item.maxlength"
        :suffix="item.suffix"
        :prepend-icon="item.icon"
        v-model.trim="value.data[item.name]"
        :rules="item.rules"
        :error-messages="getErrors(item.name)"
        :readonly="item.readonly"
        :disabled="item.disabled"
        :browser-autocomplete="item.autocomplete === false ? 'off' : null"
        @enter="enter"
        @input="
          val => {
            if (item.onInput) {
              item.onInput(val);
            }
          }
        "
      >
        <template #label>
          <strong v-if="item.isRequired" class="red--text">* </strong>
          {{ $t(item.label) }}
        </template>

        <template v-if="item.append" #append>
          <v-icon v-if="item.append.icon && item.append.action" @click="item.append.action">
            {{ item.append.icon }}
          </v-icon>
        </template>
      </text-field>
    </slot>
  </v-form>
</template>

<script>
import TextField from '@/components/TextField';
import api from '@/api';
/* eslint-disable vue/require-default-prop */
export default {
  name: 'Form',

  components: {
    'text-field': TextField,
  },

  data() {
    return {
      pw: {},
      valid: null,
    };
  },

  props: {
    fields: {
      type: Array,
    },
    value: {
      type: Object,
    },
    error: {
      type: Error,
    },
  },

  created() {
    for (const field of this.fields) {
      if (field instanceof Array) {
        for (const subfield of field) {
          if (!this.value.data[subfield.name]) {
            this.$set(this.value.data, subfield.name, '');
          }
        }
      } else if (!this.value.data[field.name]) {
        if (field.type === 'password') {
          this.$set(this.pw, field.name, false);
        }

        if (field.type === 'checkbox') {
          this.$set(this.value.data, field.name, false);
        } else {
          this.$set(this.value.data, field.name, '');
        }
      }
    }

    // Pick item in v-select by send "selected" property in it items
    const selects = this.fields.filter(item => item.type === 'select');
    selects.forEach(item => {
      const index = item.items.findIndex(q => q.selected);
      if (index !== -1) {
        this.value.data[item.name] = item.items[index].selected;
      }
    });
  },

  methods: {
    setFormData(data) {
      const setValue = (field, value) => {
        if (value !== null && value !== undefined) {
          this.$set(this.value.data, field.name, value);
        } else {
          this.$set(this.value.data, field.name, '');
        }

        if (data.can_update === false) {
          field.disabled = true;
        }
      };

      for (const field of this.fields) {
        if (field instanceof Array) {
          for (const subfield of field) {
            setValue(subfield, data[subfield.name]);
          }
        } else {
          setValue(field, data[field.name]);
        }
      }
    },

    getErrors(field) {
      if (this.error && this.error.errors && Array.isArray(this.error.errors)) {
        return api.collectErrors(this.error.errors, field);
      }

      return [];
    },

    reset() {
      this.$refs.form.reset();
    },

    validate() {
      this.$refs.form.validate();
    },

    submit(e) {
      e.preventDefault();
      return false;
    },

    enter() {
      if (this.value.valid) {
        this.$emit('enter');
      }
    },

    hasSlot(name) {
      return this.$scopedSlots[name] !== undefined;
    },
  },

  watch: {
    valid() {
      if (this.error && this.error.errors) {
        this.value.valid = this.$refs.form.validate();
      } else {
        this.value.valid = this.valid;
      }
    },

    '$i18n.locale'() {
      this.$refs.form.validate();
    },
  },
};
</script>
